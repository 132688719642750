import { createApp, markRaw } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import i18n from "@/i18n";
import vue3GoogleLogin from "vue3-google-login";
//@ts-ignore
import KnobControl from "vue-knob-control";
const pinia = createPinia();
import mitt from "mitt";
const emitter = mitt();
//@ts-ignore
import A11yDialog from "vue-a11y-dialog";

// import "@/assets/css/app.css";
// import "@/assets/css/app-dark.css";
// import "@/assets/css/bootstrap-dark.css";
// import "@/assets/css/icons.css";
// import "bootstrap/dist/css/bootstrap.css";
import "@vuepic/vue-datepicker/dist/main.css";
import "./css/styles.scss";
import "v-calendar/dist/style.css";
import "@/assets/css/bootstrap.css";
import "./css/fonts.scss";
import VCalendar from "v-calendar";
import Datepicker from "@vuepic/vue-datepicker";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import ApiHelper from "@/helpsers/api.helper";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import Vue3TouchEvents from "vue3-touch-events";
// import { initSentry } from "@/js/sentry";
// initSentry();
import dayjs from "dayjs";
require("dayjs/locale/pl");
pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

const app = createApp(App).use(pinia).use(i18n).use(Vue3TouchEvents).use(A11yDialog).use(KnobControl).use(VCalendar).provide("emitter", emitter);
dayjs.locale("pl");
if (window.location.href.includes("localhost")) {
  console.log("Application running locally");
} else {
  console.log("Application running on server");
  Sentry.init({
    //@ts-ignore
    app,
    dsn: ApiHelper.returnSentryDns(),
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["http://localhost:8082", "app2.logiplanner.eu", /^\//],
      }),
    ],
    ignoreErrors: ["HTTP/1.1 404 Not Found - Brak danych", "Cannot read properties of undefined (reading 'showAlert')"],
    tracesSampleRate: 1.0,
  });
}

app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA7ig8jP1OIw3s6bg1Vg36dkAuNH35IccQ",
    libraries: "places",
  },
  autobindAllEvents: true,
});
app.component("Datepicker", Datepicker);
app.use(router);
app.mount("#app");
import "bootstrap/dist/js/bootstrap.js";
